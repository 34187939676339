<template>
<div class="m-4">
  <tou hide-agreement/>
</div>
</template>

<script>
import tou from "@/pages/tou.vue"
export default {
  components: {
    tou
  }
}
</script>

<style>

</style>